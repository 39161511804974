@import '../../styles/colors.scss';
.portfolio-page {
  padding-left: 120px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;
  @media (max-width: 600px) {
    flex-direction: row;
  }
  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
    padding-top: 100px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    margin-left: 120px;
    margin-right: 150px;
  }

  .image-box {
    position: relative;
    flex-grow: 1;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    flex-basis: 100%; // Take up 100% of the width on small screens
    max-width: 100%; // Default to 100% width on small screens

    @media (min-width: 600px) {
      // Adjust the width at 600px viewport width
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
    }

    @media (min-width: 900px) {
      // Adjust the width at 900px viewport width
      flex-basis: calc(33% - 10px);
      max-width: calc(33% - 10px);
    }

    @media (min-width: 1200px) {
      // Adjust the width at 1200px viewport width
      flex-basis: calc(25% - 10px);
      max-width: calc(25% - 10px);
    }

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid $primary-color;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: $primary-color;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, $primary-color, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}
