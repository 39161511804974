@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';
.home-page {
  display: flex;
  .text-zone {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: $color-light;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: $primary-color;
      font-size: 24px;
      position: absolute;
      margin-top: -40px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: $primary-color;
      font-size: 24px;
      position: absolute;
      margin-top: 18px;
      margin-left: 10px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 45px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: $color-light;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: $color-light;
    background-color: $primary-color;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 8px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 12px 20px;
    border: 1px solid $primary-color;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    transition: all 0.3s ease; // Add transition for smoother hover effect
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); // Add box-shadow for 3D look

    &:hover {
      background-color: transparent;
      color: $primary-color;
      transform: translateY(-3px); // Lift the button a bit on hover
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); // Increase box-shadow on hover
    }
  }
  .portfolio-button {
    margin-right: 10px;
  }
}
