@import '../../styles/colors.scss';
.avatarImg {
  width: 200px;
  height: 200px;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20%;
  margin: 0 auto;
  margin-left: 0px;
  margin-bottom: 50px;
  // box-shadow: 0 0 20px 0px lightyellow;
  animation: avatarAnimation 1s ease-in-out;
  animation-delay: 1s;
  filter: grayscale(80%) contrast(125%) brightness(95%);
}
@keyframes avatarAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }

  .face1 {
    transform: translateZ(100px);
    color: #dd0031;
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #ffdbda;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}
.flat-button {
  color: $color-light;
  background-color: $primary-color;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 8px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 12px 20px;
  border: 1px solid $primary-color;
  margin-top: 25px;
  float: left;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
  transition: all 0.3s ease; // Add transition for smoother hover effect
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); // Add box-shadow for 3D look

  &:hover {
    background-color: transparent;
    color: $primary-color;
    transform: translateY(-3px); // Lift the button a bit on hover
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); // Increase box-shadow on hover
  }
}
.resume-button {
  margin-right: 10px;
}
