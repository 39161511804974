.logo-container {
  // z-index: 0;
  height: 90%;
  width: 100%;
  max-width: 500px;
  // opacity: 0;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 30%;
  margin: auto;
  //   max-width: 30%; // Add this line
  //   height: auto;
  //   bottom: 0;

  // .logo {
  //   position: absolute;
  //   top: auto;
  //   right: auto;
  //   bottom: auto;
  //   left: 0;
  //   margin: auto;
  //   width: 100%;
  //   z-index: 2;
  // }
  // svg {
  //   width: 100%;
  //   height: auto;
  //   bottom: 0;
  //   transform: rotateZ(30deg) !important;
  // }

  // .solid-logo {
  //   position: absolute;
  //   top: auto;
  //   right: auto;
  //   bottom: auto;
  //   left: 0;
  //   margin: auto;
  //   width: 100%;
  //   opacity: 0;
  //   transform: rotateZ(30deg) !important;
  //   z-index: 1;
  // }
}

// .svg-container {
//   stroke: #ffd700;
//   stroke-width: 10;
// }
