@import '../../styles/colors.scss';

.page {
  width: 100%;
  height: 100%;
  position: absolute;

  .top-tags {
    bottom: auto;
    top: 35px;
  }

  .tags {
    color: $primary-color; // Using variable instead of hard-coded color
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 24px;
    font-family: 'La Belle Aurore', cursive;
  }

  .bottom-tag-html {
    margin-left: -20px;
  }

  .top-tag-html {
    margin-left: -20px;
  }
  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

  .container.contact-page,
  .container.about-page,
  .container.portfolio-page {
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: $color-light;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: $primary-color;
        font-size: 24px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: $primary-color;
        font-size: 24px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-zone {
      position: absolute;
      left: 15%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }

    .text-animate-hover {
      &:hover {
        color: $primary-color;
      }
    }
  }
}
